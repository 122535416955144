<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Chi tiết cấu hình phân khúc khách hàng'">
          <template v-slot:preview>
            <b-card no-body no-footer class="full-width">
              <!-- BEGIN WIZARD -->

              <div
                class="wizard wizard-4"
                id="kt_wizard_v4"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!-- NAVIGATION -->
                <div class="wizard-nav bg-nav-light">
                  <div class="wizard-steps">
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Thông tin</div>
                        </div>
                      </div>
                    </div>
                    <div class="wizard-step ml-1" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Danh sách khách hàng</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- CONTENT -->
                <div class="card card-custom card-shadowless rounded-top-0">
                  <div class="card-body p-0">
                    <div class="row py-8 px-8 py-lg-10 px-lg-5">
                      <div class="col-xl-12">
                        <form class="form mt-0">
                          <div
                            class="full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <div class="row">
                                <b-form-group class="col-4">
                                  <template>
                                    <label
                                      >Tên: <span class="text-danger">*</span>
                                    </label>
                                  </template>
                                  <b-form-input
                                    size="sm"
                                    type="text"
                                    v-model="name"
                                    :state="validateState('name')"
                                    required
                                    placeholder="Nhập tên loại cấu hình"
                                    class="mb-3"
                                  ></b-form-input>
                                  <b-form-invalid-feedback
                                    id="input-live-feedback"
                                  >
                                    Vui lòng nhập tên loại cấu hình
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                              <b-row>
                                <b-col cols="4">
                                  <b-form-group>
                                    <template>
                                      <label>Mô tả:</label>
                                    </template>
                                    <b-form-textarea
                                      size="sm"
                                      v-model="description"
                                      :placeholder="'Thêm mô tả'"
                                      :rows="6"
                                      :max-rows="8"
                                      :state="validateState('description')"
                                    ></b-form-textarea>
                                    <b-form-invalid-feedback
                                      id="input-live-feedback"
                                      >Vui lòng nhập mô
                                      tả</b-form-invalid-feedback
                                    >
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <div
                                class="separator separator-dashed my-5"
                              ></div>
                              <b-row class="mb-2">
                                <b-col cols="12">
                                  <div class="d-flex align-items-center">
                                    <h5 class="m-0">Điều kiện:</h5>
                                    <b-button
                                      class="ml-1"
                                      style="fontweight: 600"
                                      variant="primary"
                                      size="sm"
                                      type="submit"
                                      @click="insertField"
                                    >
                                      <i
                                        style="font-size: 1rem"
                                        class="flaticon2-add-1 pr-0"
                                      ></i>
                                    </b-button>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12" md="12">
                                  <div>
                                    <b-table
                                      class="table-bordered table-hover col-md-12"
                                      :fields="fields"
                                      :items="settings"
                                      v-if="renderComponent && settings.length"
                                    >
                                      <template v-slot:cell(fieldCode)="row">
                                        <treeselect
                                          :options="listField"
                                          :multiple="false"
                                          size="sm"
                                          placeholder="Chọn Loại Điều Kiện"
                                          noResultsText="Không có kết quả"
                                          :match-keys="['label']"
                                          openDirection="bottom"
                                          :clearable="false"
                                          v-model="row.item.fieldCode"
                                        >
                                          <label
                                            slot="option-label"
                                            slot-scope="{
                                              node,
                                              labelClassName,
                                            }"
                                            :class="labelClassName"
                                          >
                                            <span :title="node.label">
                                              {{ node.label }}</span
                                            >
                                          </label>
                                        </treeselect>
                                      </template>
                                      <template v-slot:cell(operator)="row">
                                        <treeselect
                                          :options="listOperator"
                                          :multiple="false"
                                          size="sm"
                                          placeholder="Chọn toán tử"
                                          noResultsText="Không có kết quả"
                                          :match-keys="['label']"
                                          openDirection="bottom"
                                          :clearable="false"
                                          v-model="row.item.operator"
                                        >
                                          <label
                                            slot="option-label"
                                            slot-scope="{
                                              node,
                                              labelClassName,
                                            }"
                                            :class="labelClassName"
                                          >
                                            <span :title="node.label">
                                              {{ node.label }}</span
                                            >
                                          </label>
                                        </treeselect>
                                      </template>
                                      <template v-slot:cell(value)="row">
                                        <b-input
                                          v-if="
                                            row.item.fieldCode !==
                                              SEGMENT_FIELD_CODE.BILL_CREATE_DATE
                                          "
                                          type="text"
                                          size="sm"
                                          class="input-style"
                                          v-model="row.item.value"
                                          v-mask="mask"
                                        ></b-input>
                                        <b-input-group v-else>
                                          <date-picker
                                            placeholder="Từ ngày"
                                            class="form-control form-control-sm"
                                            :config="dpConfigs.date"
                                            v-model="row.item.value"
                                          ></date-picker>
                                        </b-input-group>
                                      </template>
                                      <template v-slot:cell(actions)="row">
                                        <v-icon
                                          small
                                          class="text-danger"
                                          @click="
                                            showDeleteAlert(
                                              'Xóa điều kiện đang được áp dụng !',
                                              'Bạn có chắc muốn xóa điều kiện đang được áp dụng ?',
                                              row.item,
                                              fetchDelete,
                                            )
                                          "
                                          v-b-tooltip
                                          title="Xóa"
                                          >mdi-delete</v-icon
                                        >
                                      </template>
                                    </b-table>
                                  </div>
                                </b-col>
                                <b-col cols="6" md="4"></b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <b-button
                                    style="width: 70px"
                                    variant="primary"
                                    size="sm"
                                    type="submit"
                                    @click="updateSegment"
                                    >Lưu</b-button
                                  >
                                  <b-button
                                    class="ml-2"
                                    style="width: 70px"
                                    @click="$router.go(-1)"
                                    variant="secondary"
                                    size="sm"
                                    >Hủy</b-button
                                  >
                                </b-col>
                              </b-row>
                            </v-form>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <div class="col-12 p-0 text-right mb-2">
                              <b-button
                                v-if="!onLoading"
                                size="sm"
                                variant="primary"
                                @click="fetchCustomerNotCache"
                              >
                                <i
                                  class="fa-solid fas fa-redo-alt"
                                  style="font-size: 1rem; padding-right: 5px"
                                ></i>

                                <b>Làm mới</b>
                              </b-button>

                              <b-button
                                v-if="!onLoading"
                                size="sm"
                                class="ml-2"
                                @click="exportExcel"
                              >
                                <i
                                  style="font-size: 1rem"
                                  class="far fa-file-excel"
                                ></i>
                                &nbsp; Xuất Excel
                              </b-button>
                              <b-spinner
                                v-else
                                class="mr-3"
                                label="Spinning"
                              ></b-spinner>
                            </div>
                            <b-table
                              :items="displayedCustomers"
                              :fields="fieldsTable"
                              bordered
                              hover
                              :busy="onLoading"
                            >
                              ÷÷
                              <!-- <template v-slot:cell(userCreate)="row">
                                    <span class="sumary-text">
                                        {{ row.item.userCreateSegment.employee.fullName }}
                                        {{ row.item.userCreateSegment.employee.code }}
                                    </span>
                                    </template> -->
                              <!-- <template v-slot:cell(upgradeCost)="row">
                                    <span class="text-center d-block">
                                        {{ convertPrice(row.item.upgradeCost) + ' đ' }}
                                    </span>
                                    </template> -->
                              <template v-slot:cell(description)="row">
                                <span class="sumary-text">
                                  {{ row.item.description }}
                                </span>
                              </template>
                              <template v-slot:cell(status)="row">
                                <div class="text-center" v-if="row.item.status">
                                  <span class="btn btn-success"> Bật </span>
                                </div>
                                <div v-else class="text-center">
                                  <span class="btn btn-danger"> Tắt </span>
                                </div>
                              </template>
                              <template v-slot:cell(actions)="row">
                                <b-dropdown size="sm" no-caret right>
                                  <template slot="button-content">
                                    <i
                                      style="font-size: 1rem"
                                      class="flaticon2-settings pr-0"
                                    ></i>
                                  </template>
                                  <!-- <b-dropdown-item @click="editItem(row.item)">
                                    <span
                                      style="color: #3f4254; font-size: 12px"
                                    >
                                      <i
                                        style="font-size: 1rem"
                                        class="fas fa-eye icon-color"
                                      ></i>
                                      &nbsp; Chi tiết
                                    </span>
                                  </b-dropdown-item> -->
                                  <!-- <b-dropdown-item
                                    @click="editItem(row.item)"
                                    v-if="!checkViewOnly()"
                                    >
                                    <span style="color: #3f4254; font-size: 12px">
                                        <i
                                        style="font-size: 1rem"
                                        class="flaticon2-pen icon-color"
                                        ></i>
                                        &nbsp; Cập nhật
                                    </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                    @click="showDeleteAlert(row.item)"
                                    v-show="checkPermission('USER_DELETE')"
                                    >
                                    <span style="color: #3f4254; font-size: 12px">
                                        <i
                                        style="font-size: 1rem; color: #d33"
                                        class="flaticon2-rubbish-bin-delete-button"
                                        ></i>
                                        &nbsp; Xóa
                                    </span>
                                    </b-dropdown-item> -->
                                </b-dropdown>
                              </template>
                            </b-table>
                            <b-row>
                              <b-col>
                                <p
                                  class="mt-3 text-dark"
                                  style="font-weight: 500"
                                >
                                  Tổng số khách hàng:
                                  {{ listCustomer.length }}
                                </p>
                              </b-col>

                              <div class="d-flex justify-content-end">
                                <b-col>
                                  <b-pagination
                                    v-model="currentPage"
                                    :total-rows="listCustomer.length"
                                    :per-page="perPage"
                                  ></b-pagination>
                                </b-col>
                              </div>
                            </b-row>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END WIZARD -->
            </b-card>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
<style scoped>
.gender-appellation {
  display: flex;
  gap: 4px;
}

.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import moment from 'moment';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { currencyMask } from './../../../utils/common';
import {
  SEGMENT_FIELD_CODE,
  OPERATOR_CODE_RFM,
  HEADER_TABLE_RFM_CUSTOMER,
} from '@/utils/enum';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';

// Import datePicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { v4 } from 'uuid';
import moment from 'moment';

import { showDeleteAlert } from '@/utils/sweet-alert2';

// import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { xoa_dau, convertPrice, formatDate } from '../../../utils/common';
import { map } from 'lodash';

import XLSX from 'xlsx';

export default {
  mixins: [validationMixin],
  data() {
    return {
      onLoading: false,
      currentTab: 1,
      perPage: 10,
      currentPage: 1,
      listCustomer: [],
      segmentId: null,
      SEGMENT_FIELD_CODE,
      HEADER_TABLE_RFM_CUSTOMER,
      settings: [],
      renderComponent: true,
      isGetCache: 1,
      currency: currencyMask,
      listField: [{ id: '', label: 'Chọn loại điều kiện' }],
      listOperator: [{ id: '', label: 'Chọn loại toán tử' }],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      isNew: true,
      valid: true,
      name: '',
      description: '',
      fieldsTable: [
        {
          key: 'id',
          label: 'ID',
          thStyle: { textAlign: 'center', width: '10%' },
        },
        {
          key: 'fullName',
          label: 'Tên',
          thStyle: { textAlign: 'center', width: '20%' },
        },
        {
          key: 'phoneNo',
          label: 'Điện thoại',
          thStyle: { textAlign: 'center', width: '15%' },
        },
      ],
      fields: [
        {
          key: 'fieldCode',
          label: 'Loại điều kiện',
          thStyle: { textAlign: 'center', width: '30%' },
        },
        {
          key: 'operator',
          label: 'Toán tử',
          thStyle: { textAlign: 'center', width: '30%' },
        },
        {
          key: 'value',
          label: 'Giá trị',
          thStyle: { textAlign: 'center', width: '30%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
    };
  },
  validations: {
    name: { required },
    description: { minLength: minLength(1) },
    settings: { required, minLength: minLength(1), maxLength: maxLength(5) },
  },
  components: {
    KTCodePreview,
    Treeselect,
    // datePicker,
    // Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình phân khúc khách hàng', route: '/customer-segment' },
      { title: 'Cập nhật cấu hình phân khúc khách hàng' },
    ]);
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: this.currentTab,
      clickableSteps: true,
    });

    wizard.on('beforeNext', function(/*wizardObj*/) {});

    wizard.on('change', function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  created() {
    this.segmentId = this.$route.query.id;
    this.currentTab = this.$route.query.currentTab
      ? this.$route.query.currentTab
      : 1;
    this.fetchFieldCode();
    this.fetchOperator();
    this.fetchDetailSegment();
    this.fetchCustomerForSegment();
  },
  methods: {
    showDeleteAlert,
    formatDate,
    convertPrice,
    exportExcel() {
      const data = this.listCustomer;
      if (!data.length) {
        return;
      }
      console.log(map(this.fieldsTable, 'label'));
      let wsData = [map(this.fieldsTable, 'label')];

      for (let i = 0; i < data.length; i++) {
        let row = data[i];
        const arr = [row.id, row.fullName, row.phoneNo];

        if (row.lastDateBuyed) {
          const lastDateBuyed = this.formatDate(
            row.lastDateBuyed,
            'DD-MM-YYYY HH:mm:ss',
          );
          arr.push(lastDateBuyed);
        }

        if (row.totalBill) {
          arr.push(row.totalBill);
        }

        if (row.totalBuyedAmount) {
          const totalBuyedAmount = this.convertPrice(row.totalBuyedAmount);
          arr.push(totalBuyedAmount);
        }

        wsData.push(arr);
      }

      const ws = XLSX.utils.aoa_to_sheet(wsData);

      ws['!cols'] = wsData[0].map((_, i) => {
        const widths = wsData.map((row) => (`${row[i]}` || '').length);
        const maxLength = Math.max(...widths);
        return { wch: maxLength };
      });

      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      XLSX.writeFile(
        wb,
        `segment-${this.formatDate(Date.now(), 'DD-MM-YYYY HH:mm:ss')}.xlsx`,
      );
    },
    // deleteField(itemSelected) {},
    fetchDelete(itemSelected) {
      // showDeleteAlert(
      //   'Xóa điều kiện đang được áp dụng!',
      //   'Bạn muốn xóa điều kiện đang được cấu hình ?',
      // );

      // console.log(item)
      ApiService.put(`/rfm/operator-segment`, {
        id: itemSelected.rfmSegmentId,
        operatorId: itemSelected.id,
      })
        .then(() => {})
        .catch(() => {});
      this.makeToastSuccess('Xóa điều kiện thành công');

      this.settings = this.settings.filter(
        (item) => item.id !== itemSelected.id,
      );
    },
    newId() {
      return v4();
    },
    format(value) {
      this.birthday = value;
      return value;
    },
    insertField() {
      const item = {
        id: this.newId(),
        fieldCode: '',
        operator: '',
        logicOperator: 'AND',
        value: '',
      };
      this.settings.push(item);
    },
    fetchCustomerNotCache: async function() {
      this.isGetCache = 0;
      this.fetchCustomerForSegment();
    },
    fetchCustomerForSegment: async function() {
      this.onLoading = true;
      ApiService.query(
        `/rfm/${
          this.segmentId
        }/customer?page=1&limit=999999999&isGetCache=${+this.isGetCache}`,
      )
        .then((response) => {
          const data = response.data.data;
          for (let i = 0; i < Object.keys(data[0]).length; i++) {
            const item = Object.keys(data[0])[i];
            if (item === HEADER_TABLE_RFM_CUSTOMER.DATE_LAST_BUYED) {
              this.fieldsTable.push({
                key: item,
                label: 'Ngày mua gần nhất',
                thStyle: { textAlign: 'center', width: '20%' },
                formatter: (value) => {
                  return this.formatDate(value, 'DD-MM-YYYY HH:mm:ss');
                },
              });
            }
            if (item === HEADER_TABLE_RFM_CUSTOMER.TOTAL_BILL) {
              this.fieldsTable.push({
                key: item,
                label: 'Tổng Hóa Đơn',
                thStyle: { textAlign: 'center', width: '10%' },
              });
            }
            if (item === HEADER_TABLE_RFM_CUSTOMER.TOTAL_BUYED_AMOUNT_BILL) {
              this.fieldsTable.push({
                key: item,
                label: 'Tổng Giá Trị Hóa Đơn',
                thStyle: { textAlign: 'center', width: '20%' },
                formatter: (value) => {
                  return this.convertPrice(value);
                },
              });
            }
          }
          this.listCustomer = data;
          this.isGetCache = 1;
          this.onLoading = false;

          // this.fieldsTable.push();
        })
        .catch(() => {
          this.isGetCache = 1;
          this.onLoading = false;
        });
    },
    fetchDetailSegment: async function() {
      ApiService.query(`/rfm/${this.segmentId}/detail`).then((response) => {
        const data = response.data.data;
        this.name = data.name;
        this.description = data.description;
        for (const item of data.settingSegment) {
          const _item = {
            ...item,
            operator: OPERATOR_CODE_RFM[item.operator],
          };
          if (item.fieldCode === SEGMENT_FIELD_CODE.BILL_CREATE_DATE) {
            _item['value'] = moment(item.value, 'yyyy-MM-DD').format(
              'DD/MM/YYYY',
            );
          }
          this.settings.push(_item);
        }
      });
    },
    fetchFieldCode: async function() {
      ApiService.query(`/rfm/list-field-code`).then((response) => {
        const data = response.data.data;
        for (const item of data) {
          this.listField.push(item);
        }
      });
    },
    fetchOperator: async function() {
      ApiService.query(`/rfm/list-operator`).then((response) => {
        const data = response.data.data;
        for (const item of data) {
          this.listOperator.push(item);
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    updateSegment: async function() {
      this.isNew = false;
      this.$v.name.$touch();
      this.$v.settings.$touch();
      if (this.$v.name.$anyError) {
        return;
      }
      if (this.$v.settings.$anyError) {
        this.makeToastFaile('Vui lòng cấu hình điều kiện cho segment');
        return;
      }

      const _setting = this.settings.map((item) => {
        if (item.fieldCode === SEGMENT_FIELD_CODE.BILL_CREATE_DATE) {
          item['value'] = moment(item.value, 'DD/MM/YYYY').format('yyyy-MM-DD');
        } else {
          item['value'] = +item.value.replace(/,/g, '');
        }
        return item;
      });

      const data = {
        id: this.segmentId,
        name: this.name,
        description: this.description,
        settings: _setting,
      };
      ApiService.post('rfm/update', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.settings = [];
            this.fetchDetailSegment();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch((error) => {
          console.log('error: ', error);
          this.makeToastFaile('Thêm loại khách hàng bị lỗi!!!!');
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchEmployee: async function() {
      this.optionEmployee = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee.push(store);
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      this.selectedDistrict = null;
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onContext(ctx) {
      if (ctx.selectedYMD == '') {
        this.birthday = '';
      } else {
        this.birthday = ctx.selectedYMD;
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
  computed: {
    displayedCustomers() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.listCustomer.slice(start, end);
    },
  },
};
</script>
